import cloneDeep from "lodash/fp/cloneDeep";

export const CopyDayTo = (dayToCopy, copyList) => {
	let original;

	return {
		invoke: (schedule) => {
			original = schedule.clone();

			const source = schedule.getDay(dayToCopy);
			const getDayAndCopy = (name) => schedule.getDay(name).copyEventsFromDay(source);

			copyList.forEach(getDayAndCopy);
			return schedule;
		},
		undo: () => original,
	};
};

export const AddEvent = (day, event) => {
	return {
		invoke: (schedule) => {
			schedule.getDay(day).addEvent(event);
			return schedule;
		},
		undo: schedule => {
			schedule.getDay(day).removeEvent(event);
			return schedule;
		},
	};
};

export const EditEvent = (day, originalEvent, updatedEvent) => {
	const original = cloneDeep(originalEvent);
	const updated = cloneDeep(updatedEvent);
	let missingEvent = false;

	return {
		invoke: (schedule) => {
			let event = schedule.getDay(day).findEventAtTime(original.start);
			missingEvent = !event;
			if(missingEvent) return schedule;

			event.start = updatedEvent.start;
			event.setpoint = updatedEvent.setpoint;
			return schedule;
		},
		undo: schedule => {
			if(missingEvent) return schedule;

			let event = schedule.getDay(day).findEventAtTime(updated.start);
			event.start = original.start;
			event.setpoint = original.setpoint;
			return schedule;
		},
	};
};

export const DeleteEvent = (day, start) => {
	let original;
	return {
		invoke: (schedule) => {
			const event = schedule.getDay(day).removeEvent({start});
			original = cloneDeep(event);
			return schedule;
		},
		undo: schedule => {
			schedule.getDay(day).addEvent(original);
			return schedule;
		},
	};
};
