import Hammer from "hammerjs";

export default {
	bind: function(el, binding, vNode) {
		if(typeof binding.value !== "function") {
			const compName = vNode.context.name;
			let warning = `[longpress]: provided expression '${binding.expression}' is not a function!`;
			if(compName) warning += `Found in component '${compName}'`;
			console.warning(warning);
		}

		// Create manager for el
		var manager = new Hammer.Manager(el);
		// Create recognizer
		var Press = new Hammer.Press({
			time: binding.arg || 500,
		});
		// Add the recognizer to the manager
		manager.add(Press);
		// Sub to event
		manager.on("press", function(ev) {
			binding.value(ev);
		});
	},
};
