import Vue from 'vue';
import "./plugins/composition-api";
import "./plugins/portal";

import App from './App.vue';

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./services/firebase";
import router from './router';
import vuetify from './plugins/vuetify';

import useSession from "@/composables/useSession";

//import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import "@/styles/global.scss";
import "@/filters";
import "@/directives"
import './registerServiceWorker'

Vue.config.productionTip = false;

const session = useSession();

function isUserRegistering() {
	let registration = window.localStorage.getItem("registration");
	if(!registration) return false;
	try {
		registration = JSON.parse(registration);
		return registration.incomplete;
	} catch (error) {
		if(process.env.NODE_ENV !== "test") console.error(error);
		return false;
	}
}


let app;
onAuthStateChanged(auth, async (user) => {
	if(isUserRegistering()) {
		if(!app) app = initializeApp();
		if(router.currentRoute.name !== "Registration") router.replace({name:"Registration"});
		return;
	}

	if(user && !session.initialized.value) {
		await session.create(user.uid).catch(console.error)
	}else if(!user) {
		await session.terminate();
	}

	// initialize after we've the user's session (if possible)
	if(!app) app = initializeApp();
});

const initializeApp = () => {
	return new Vue({
		router,
		vuetify,
		render: h => h(App)
	}).$mount('#app')
};
