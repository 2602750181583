import { ref, computed, watch } from "@vue/composition-api";
import { schedulesCollectionGroup } from "@/services/firebase";
import { onSnapshot, where, query, setDoc } from "firebase/firestore";

import { scheduleConverter } from "@/models/schedule";
import reducer from "./reducer";

const state = ref(null);
const selectedId = ref(null);
const schedules = ref({});
const loading = ref(false);

watch([schedules, selectedId], () => {
	const id = selectedId.value;
	const schedule = id ? (schedules.value[id] || null) : null;
	state.value = schedule;
});



export const useSchedule = () => {
	const history = [];

	const commit = (schedule) => {
		if(!schedule || !schedule.doc) return;

		setDoc(schedule.doc.withConverter(scheduleConverter), schedule, {merge:true});
	};

	const invoke = async action => { // action = { type, prop:argA, prop:argB, ... }
		const command = reducer(action);
		if(!command) throw new Error(`Invalid schedule action: "${action.type}"`);

		state.value = await command.invoke(state.value.clone());
		history.push(command);
		if(command.commitResult !== false) commit(state.value);
	};

	const undo = async () => {
		const command = history.pop();
		if(!command) return;

		state.value = await command.undo(state.value.clone());
		if(command.commitResult !== false) commit(state.value);
	};


	return {
		schedules: computed(() => schedules.value),
		schedule: computed(() => state.value),
		setActiveScheduleId: (id) => selectedId.value = id,
		loaded: computed(() => state.value !== null),
		loading: computed(() => loading.value),
		invoke, undo
	};
};

export const initializeUserSchedules = (userId) => {
	loading.value = true;
	const q = query(schedulesCollectionGroup, where("tenants", "array-contains", userId))
		.withConverter(scheduleConverter)
	return onSnapshot(q, snap => {
		loading.value = false;
		if(snap.empty) {
			schedules.value = {};
			return;
		}
		snap.forEach(handleResidenceSnapshot);
	}, () => loading.value = false);
};

export const resetUserSchedules = () => {
	schedules.value = {};
	state.value = null;
	selectedId.value = null;
	loading.value = false;
};

const handleResidenceSnapshot = (snapshot) => {
	if(snapshot.empty) return;

	const schedule = snapshot.data();
	schedules.value = {
		...schedules.value,
		...{[snapshot.id]: schedule}
	};
	if(!selectedId.value) selectedId.value = snapshot?.id || null;
};
