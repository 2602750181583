<template>
	<v-app-bar app>
		<v-app-bar-nav-icon @click="$emit('nav:clicked', $event)" :hover="false"/>

		<v-toolbar-title id="page-title">{{title}}</v-toolbar-title>

		<v-spacer/>

		<slot name="header-options"></slot>
		<template v-slot:extension v-if="extendHeader">
			<portal-target class="header-extension-portal" name="header-extension"></portal-target>
		</template>
	</v-app-bar>
</template>

<script>
import useApplicationTitle from "@/composables/useApplicationTitle";

export default {
	name: "AppDefaultHeader",
	components: { },
	props: {
		extendHeader: { type:Boolean, default:false }
	},
	emits: ["nav:clicked"],

	setup() {
		const { title } = useApplicationTitle();
		return { title, log:console.log };
	},
};
</script>

<style lang="scss">
	#header-nav-icon-portal .v-btn {
		margin-left: -12px;
	}
</style>
