<template>
	<v-fab-transition>
		<v-btn fab fixed bottom right
			:class="{ 'has-snackbar': moveForSnackbar }"
			v-on="on"
			v-bind="attrs"
			v-show="attrs.show && !hideForSnackbar"
		>
			<v-icon>{{icon}}</v-icon>
		</v-btn>
	</v-fab-transition>
</template>

<script>
import { watch, onUnmounted, computed } from "@vue/composition-api"
import useFab from "@/composables/useFab";
import useSnackbar from "@/composables/useSnackbar";
import useAppUpdate from "@/composables/useAppUpdate";

export default {
	name: "AppFab",
	props: {
		behaviorWithSnackbar: {
			validator: value => ["move", "hide", "none"].includes(value),
			default:"none"
		},
	},
	components: {},

	setup(props, {root}) {
		const { attrs, icon, on, hide, setIcon, setAttrs } = useFab();

		function applyFabSettings(settings = {}) {
			const { icon, ...attrs } = settings;
			if(icon) setIcon(icon);
			if(attrs) setAttrs(attrs);
		}
		applyFabSettings(root.$route.meta.fab);

		const applyFabSettingsOnWatch = () => applyFabSettings(root.$route.meta.fab);
		watch(() => root.$route.path, applyFabSettingsOnWatch);


		const handler = root.$router.afterEach(hide);
		onUnmounted(handler);
		const appUpdate = useAppUpdate();


		const snackbar = useSnackbar();
		const isSnackbarVisibleOnSmallScreen = computed(() => {
			const xsBreakpoint = root.$vuetify.breakpoint.xs;
			const snackbarVisibleOnSmallDevice = snackbar.state.show && xsBreakpoint;
			if(snackbarVisibleOnSmallDevice) return true;

			const width = root.$vuetify.breakpoint.width;
			const xsThreshold = root.$vuetify.breakpoint.thresholds.xs;
			const offsetForFab = 145;
			const appUpdateSnackbarVisible = appUpdate.showNotification.value && width < (xsThreshold + offsetForFab);
			return appUpdateSnackbarVisible;
		});

		const moveForSnackbar = computed(() => props.behaviorWithSnackbar === "move" && isSnackbarVisibleOnSmallScreen.value);
		const hideForSnackbar = computed(() => props.behaviorWithSnackbar === "hide" && isSnackbarVisibleOnSmallScreen.value);


		return { attrs, on, icon, moveForSnackbar, hideForSnackbar };
	},
};
</script>

<style lang="scss" scoped>
	.v-btn {
		z-index: 999;
		transition: bottom 0.25s ease;
	}

	.v-btn.has-snackbar {
		bottom: 68px;
		transition: bottom 0.25s ease;
	}
</style>
