import { ref, computed } from "@vue/composition-api";
import { initializeUserSchedules, resetUserSchedules } from "@/composables/schedule";
import useUser from "@/composables/useUser";
import router from "@/router"

const initialized = ref(false);


export default function useSession() {
	const { isAdmin, fetchUserProfile, beforeLogout } = useUser();

	async function create(uid) {
		if(initialized.value) return;

		await fetchUserProfile(uid);
		const handle = initializeUserSchedules(uid);
		beforeLogout.subscribe(handle);

		if(router.currentRoute.path === "/login") {
			if(isAdmin.value) router.replace("/admin");
			else router.replace("/dashboard");
		}
		initialized.value = true;
	}

	function terminate() {
		resetUserSchedules();

		if(initialized.value && router.currentRoute.path !== "/login") router.replace("/login");
		initialized.value = false;
	}


	return {
		initialized: computed(() => initialized.value),

		create,
		terminate,
	};
}
