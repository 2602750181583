import Vue from "vue"
import VueRouter from "vue-router"

import { getIdTokenResult } from "firebase/auth";
import { auth } from "@/services/firebase";
import useApplicationTitle from "@/composables/useApplicationTitle";

const { setTitle } = useApplicationTitle();

Vue.use(VueRouter)

function extractQueryParamFromURL(urlStr, paramName, defVal) {
	try {
		return new URL(urlStr).searchParams.get(paramName);
	} catch(err) {
		return defVal;
	}
}

const routes = [
	{
		path: "/login",
		name: "Login",
		component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
		meta: {
			title: "Login",
			requiresAuth: false,
			showHeader: false,
			showSidenav: false,
		}
	},

	{
		path:"/__auth/action",
		meta: {
			requiresAuth: false,
			showHeader: false,
			showSidenav: false,
		},
		beforeEnter(to, _from, next) {
			if(to.query.mode === "resetPassword") {
				next({ name:"Reset Password", query:to.query });
			} else if(to.query.mode === "signIn") {
				const { continueUrl="", ...query } = to.query;
				const invitation = extractQueryParamFromURL(continueUrl, "invitation") || "";
				next({ name:"Registration", query:{ ...query, invitation } });
			} else {
				next("login");
			}
		},
	},
	{
		path: "/forgot_password",
		name: "Forgot Password",
		component: () => import(/* webpackChunkName: "login" */ "../views/ForgotPassword.vue"),
		meta: {
			title: "Forgot Password",
			requiresAuth: false,
			showHeader: false,
			showSidenav: false,
		}
	},
	{
		path: "/reset_password",
		name: "Reset Password",
		component: () => import(/* webpackChunkName: "login" */ "../views/ResetPassword.vue"),
		meta: {
			title: "Reset Password",
			requiresAuth: false,
			showHeader: false,
			showSidenav: false,
		}
	},

	{
		path: "/dashboard",
		name: "Dashboard",
		component: () => import(/* webpackChunkName: "dashboard" */ "../views/Home.vue"),
		meta: {
			title: "Dashboard",
			requiresAuth: true,
		}
	},

	{
		path: "/admin",
		name: "Admin",
		component: () => import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
		meta: {
			title: "Administration",
			roles: ["admin"],
			extendHeader: true,
			fab: {
				show: true,
				color: "green",
				icon: "mdi-plus"
			}
		},

		children: [{
			path:"invite",
			name:"InviteTenant",
			components: {
				invite: () => import(/* webpackChunkName: "admin" */ "../components/tenant/InviteTenantDialog.vue"),
			},
			meta: { fab:false, extendHeader:true },
		}],
	},

	{
		path: "/user-registration",
		name: "Registration",
		component: () => import(/* webpackChunkName: "login" */ '../views/Registration.vue'),
		meta: {
			requiresAuth: false,
			showHeader: false,
			showSidenav: false,
		}
	},
	{
		path: "/schedules",
		component: () => import(/* webpackChunkName: "schedules" */ "../views/SchedulesPage.vue"),
		children: [{
			path: "",
			beforeEnter: (_to, _from, next) => next("/dashboard"),
		},{
			name: "Schedules",
			path: ":id",
			component: () => import(/* webpackChunkName: "schedules" */ "../views/ScheduleOverview.vue"),
			meta: {
				fab: {
					show: true,
					color: "green",
					icon: "mdi-plus"
				},
			},
			children: [{
				name: "CreateEvent",
				path: "create",
				components: {
					create: () => import(/* webpackChunkName: "schedules" */ "../components/schedule/TimeEventDialog.vue"),
				}
			}, {
				name: "EditEvent",
				path: "edit/:index",
				components: {
					edit: () => import(/* webpackChunkName: "schedules" */ "../components/schedule/TimeEventDialog.vue"),
				}
			}],
		}],
	},


	{ path: "*", redirect: { name: "Dashboard" } }, // Redirect on bad route
]

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, _from, next) => {
	const requiresAuth = to.matched.some(x => x.meta.requiresAuth !== false ); // routes requiresAuth by default

	const loggedOut = !auth.currentUser;
	const loginRequired = requiresAuth && loggedOut;
	if(loginRequired) return next("/login");


	const routeRoles = to.meta.roles || [];
	if(!loggedOut && routeRoles.length) {
		getIdTokenResult(auth.currentUser).then(token => {
			const hasRole = routeRoles.includes(token.claims.role);
			if(hasRole) return next();
			return next(false);
		}).catch(next);
	} else {
		return next();
	}
});

router.afterEach((route) => {
	if(route.meta.title) setTitle(route.meta.title);
});


export default router;
