import cloneDeep from "lodash/fp/cloneDeep";

export default class Day {
	constructor(name, events = []) {
		this.name = name;
		this.events = events.slice().sort(eventComparator);
		this._nextDay = null;
		this._previousDay = null;
	}


	get next() { return this._nextDay; }
	set next(value) { this._nextDay = value || null; }

	get prev() { return this._previousDay; }
	set prev(value) { this._previousDay = value || null; }

	addEvent(event) {
		this.events = [...this.events, event].sort(eventComparator);
	}

	removeEvent(event) {
		const index = this.events.findIndex( ev => ev.start === event.start );
		if(index === -1) return null;

		const removed = this.events.splice(index, 1);
		return removed[0];
	}

	findEventAtTime(time) {
		return this.events.find( ev => ev.start === time ) || null;
	}

	copyEventsFromDay(other) {
		this.events = cloneDeep(other.events);
	}

	getActiveEventAt(hours, minutes) {
		for(let i=this.events.length-1; i>=0; --i) {
			const event = { ...this.events[i] };
			const start = event.start.split(":");
			const startHours = Number.parseInt(start[0]);
			const startMinutes = Number.parseInt(start[1]);
			if(startHours < hours) return { day:this, event };
			if(startHours == hours && startMinutes <= minutes) return { day:this, event };
		}

		// Find event in previous days if necessary
		let day = this;
		for(let i=0; i<7; ++i) {
			day = day.prev;
			if(!day) return null;

			const events = day.events;
			if(events.length) return { day, event:events[events.length - 1] };
		}
		return null;
	}

	getNextEventAfter(event) {
		const index = this.events.findIndex( ev => ev.start === event.start );
		if(index === -1) return null;
		if(index < this.events.length - 1) return { day:this, event:this.events[index + 1] };

		// Find event in upcoming days if necessary
		let day = this;
		for(let i=0; i<7; ++i) {
			day = day.next;
			if(!day) return null;

			const events = day.events;
			if(events.length) return { day, event:events[0] };
		}
		return null;
	}


	static relateDays(current, previous, next) {
		current.prev = previous;
		current.next = next;
	}
}

export function eventComparator(a, b) {
	if(a.start > b.start) return 1;
	if(a.start < b.start) return -1;
	return 0;
}
