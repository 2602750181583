import isString from "lodash/fp/isString";

export default (value) => {
	const nonStringOrMissingColon = !isString(value) || !value.includes(":");
	if(nonStringOrMissingColon) return value;

	let [hours, minutes] = value.split(":");
	hours = +hours;

	const postfix = hours < 12 ? "AM" : "PM";
	if(hours === 0) hours = hours = 12;
	if(hours > 12) hours = hours - 12;

	return `${hours}:${minutes} ${postfix}`;
};
