import { ref, reactive, computed } from "@vue/composition-api";
import { getDoc, doc } from "firebase/firestore";
import { signInWithEmailAndPassword, signOut, getIdTokenResult } from "firebase/auth";

import Subject from "@/lib/subject";
import { auth, usersCollection } from "@/services/firebase";

const state = reactive({
	id: null,
	profile: null,
});
const claims = ref({});
const beforeLogout$ = new Subject();

export default function() {

	const login = async ({email, password}) => {
		return signInWithEmailAndPassword(auth, email, password);
	};

	const fetchUserProfile = async (uid) => {
		const ref = doc(usersCollection, uid);
		const userDoc = await getDoc(ref);
		if(!userDoc.exists) return logout();

		state.id = userDoc.id;
		state.profile = userDoc.data();

		const token = await getIdTokenResult(auth.currentUser);
		claims.value = token.claims;
	};

	const logout = async () => {
		beforeLogout$.fire();
		await signOut(auth);
		state.id = null;
		state.profile = null;
	};

	const name = computed(() => {
		if(!state.profile) return "";
		const { first, last } = state.profile.name;
		return `${first} ${last}`;
	});


	return {
		user: computed(() => state),
		name,
		adminSite: computed(() => claims.value.admin_site_id || null),

		isAdmin: computed(() => claims.value.role === "admin"),
		loggedIn: computed(() => state.profile !== null),

		login,
		logout,
		beforeLogout: beforeLogout$.asObservable(),
		fetchUserProfile,
	};
}
