<template>
	<v-snackbar bottom center tile
		v-model="snackbar.state.show"
		:timeout="timeout"
		:class="{'position-above-fab': moveForFab }"
	>
		{{ message }}

		<template v-if="hasAction && isIconAction" v-slot:action="{ attrs }">
			<v-btn icon
				v-bind="attrs"
				v-on="on"
				@click="snackbar.hide()"
			>
				<v-icon>{{snackbar.state.action.icon}}</v-icon>
			</v-btn>
		</template>

		<template v-else-if="hasAction" v-slot:action="{ attrs }">
			<v-btn text
				:color="snackbar.state.action.color"
				v-bind="attrs"
				v-on="on"
				@click="snackbar.hide()"
			>
				{{snackbar.state.action.text || "Close"}}
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
import { computed } from "@vue/composition-api"
import useSnackbar from "@/composables/useSnackbar";
import useFab from "@/composables/useFab";

export default {
	name: "AppSnackbar",
	props: {
		behaviorWithFab: {
			validator: value => ["move", "none"].includes(value),
			default:"none"
		}
	},
	components: {},

	setup(props, {root}) {
		const snackbar = useSnackbar();
		const fab = useFab();

		const moveForFab = computed(() => props.behaviorWithFab === "move" && fab.attrs.value.show && root.$vuetify.breakpoint.xs);
		return {
			snackbar,
			moveForFab,
			timeout: computed(() => snackbar.state.timeout),
			message: computed(() => snackbar.state.message),
			hasAction: computed(() => snackbar.state.action.enabled),
			isIconAction: computed(() => !!snackbar.state.action.icon),
			on: computed(() => snackbar.state.action.on || {}),
		};
	},
};
</script>

<style lang="scss" scoped>
	.v-snack {
		z-index: 999;
		transition: padding-bottom 0.25s ease;
	}

	.position-above-fab {
		padding-bottom: 84px !important;
		transition: padding-bottom 0.25s ease;
	}
</style>
