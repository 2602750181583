import { initializeApp } from "firebase/app";
import { initializeAuth, connectAuthEmulator, indexedDBLocalPersistence, browserLocalPersistence } from "firebase/auth";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { enableNetwork, disableNetwork, getFirestore, collection, collectionGroup, arrayUnion, arrayRemove, Timestamp, enableIndexedDbPersistence, connectFirestoreEmulator } from "firebase/firestore";
import firebaseConfig from "@/config/firebase.json";

const app = initializeApp(firebaseConfig);
const auth = initializeAuth(app, {
	persistence: [indexedDBLocalPersistence, browserLocalPersistence],
});
const db = getFirestore();
const functions = getFunctions(app);


if(location.hostname === "localhost") {
	const emulators = require("@/../firebase.json").emulators;
	connectAuthEmulator(auth, `http://localhost:${emulators.auth.port}`, { disableWarnings:true });
	connectFirestoreEmulator(db, "localhost", emulators.firestore.port);
	connectFunctionsEmulator(functions, "localhost", emulators.functions.port);
}


enableIndexedDbPersistence(db)
	.catch(err => {
		console.error("Could not enable local persistence", err);
	});

window.addEventListener("online", () => {
	enableNetwork(db)
		.catch(console.error);
})
window.addEventListener("offline", () => {
	disableNetwork(db)
		.catch(console.error);
});
if(navigator.onLine === false) disableNetwork(db);


// collection references
const usersCollection = collection(db, "users");
const schedulesCollectionGroup = collectionGroup(db, "schedules");

const inviteUser = httpsCallable(functions, "inviteUser");
const registerUser = httpsCallable(functions, "registerUser");


const FieldValue = { arrayUnion, arrayRemove };
export {
	db, auth,
	usersCollection, schedulesCollectionGroup,
	inviteUser, registerUser,

	FieldValue,
	Timestamp,
};
