import { AddEvent, DeleteEvent, EditEvent, CopyDayTo } from "./actions";

export default function reducer(action) {
	action = action || {};
	switch(action.type) {
		case "addEvent":    return AddEvent(action.day, action.event); // day, event
		case "deleteEvent": return DeleteEvent(action.day, action.start); // day, start
		case "editEvent":   return EditEvent(action.day, action.oldValue, action.newValue); // day, originalEvent, updatedEvent
		case "copyDay":     return CopyDayTo(action.day, action.list); // dayToCopy, daysToCopyTo
		default: throw new Error(`Unsupported action type "${action.type}". Action: ${JSON.stringify(action)}`);
	}
}
