<template>
	<v-app id="inspire">
		<PortalTarget name="application-header" v-if="loggedIn">
			<v-fade-transition>
				<AppDefaultHeader v-if="showHeader"
					:extendHeader="extendHeader"
					@nav:clicked="drawer = true"
				>
					<template v-slot:header-options>
						<portal-target class="header-options-portal" name="header-options"></portal-target>
					</template>
				</AppDefaultHeader>
			</v-fade-transition>
		</PortalTarget>

		<AppSidenav v-if="loggedIn && showSidenav" :items="items" :open.sync="drawer"/>

		<v-main>
			<v-container fill-height fluid class="pa-0">
				<router-view></router-view>
			</v-container>
		</v-main>

		<AppFab behavior-with-snackbar="move"/>
		<AppSnackbar behavior-with-fab="none"/>
		<AppUpdateNotification/>

		<PortalTarget id="bottom-nav" name="application-bottom-nav" v-if="loggedIn"></PortalTarget>
	</v-app>
</template>

<script>
import { ref, computed } from "@vue/composition-api"
import map from "lodash/fp/map";

import { PortalTarget } from "portal-vue";
import AppSidenav from "./components/app/AppSidenav";
import AppDefaultHeader from "./components/app/AppDefaultHeader";
import AppFab from "./components/app/AppFab";
import AppSnackbar from "./components/app/AppSnackbar";
import AppUpdateNotification from "@/components/app/AppUpdateNotification";
import { useSchedule } from "@/composables/schedule";
import useUser from "@/composables/useUser";
import useFab from "@/composables/useFab";

export default {
	name: 'App',
	components: { AppSidenav, AppDefaultHeader, AppFab, AppSnackbar, AppUpdateNotification, PortalTarget },

	setup(props, {root}) {
		const drawer = ref(null);

		const { schedules } = useSchedule();
		const { isAdmin, loggedIn, user } = useUser();

		const items = computed(() => {
			const list = [];

			if(isAdmin.value) {
				list.push({ title: 'Admin', icon: 'mdi-shield-account', link: "/admin" });
			} else {
				list.push({ title: 'Dashboard', icon: 'mdi-view-dashboard', link: "/dashboard" });
			}

			const scheduleItems = map( (schedule) => {
				return { title: schedule.name, icon: "mdi-calendar", link: `/schedules/${schedule.id}` };
			}, schedules.value);

			if(scheduleItems.length > 0) {
				const residenceName = user.value?.profile?.residence?.name;
				const header = residenceName ? `Schedules - ${residenceName}` : "Schedules";
				list.push({ group: true, header, items: scheduleItems, });
			}

			return list;
		});

		// If things become more complex, move towards "smart layouts".
		// See https://itnext.io/vue-tricks-smart-layouts-for-vuejs-5c61a472b69b
		const showHeader = computed(() => root.$route.meta.showHeader !== false);
		const showSidenav = computed(() => root.$route.meta.showSidenav !== false);
		const extendHeader = computed(() => root.$route.meta.extendHeader === true);

		const fab = useFab();

		return { drawer, items, showHeader, showSidenav, loggedIn, fab, extendHeader };
	},
};
</script>

<style lang="scss">
	@import "@/styles/local.scss";

	#inspire {
		background: $app-background-color;
	}

	// Offsets buttons wrapped in portal on the right side of the header
	.header-options-portal {
		margin-right: -12px;
	}

	@media only screen and (orientation: portrait) {
		.force-landscape {
			transform: rotate(-90deg);
			transform-origin: left top;
			width: 100vh;
			overflow-x: hidden;
			position: absolute;
			top: 100%;
			left: 0;
			zoom: normal;
		}
	}

	@media only screen and (orientation: landscape) {
		.force-landscape {
			zoom: normal;
		}
	}
</style>
