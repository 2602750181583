<template>
	<v-navigation-drawer v-model="drawer" app>

		<v-list-item>
			<v-list-item-content>
				<v-list-item-title class="title">{{siteName}}</v-list-item-title>
				<v-list-item-subtitle>
					{{name}}
				</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>

		<v-divider></v-divider>

		<v-list id="nav-links" dense nav class="d-flex flex-column">

			<v-list-item v-if="appUpdateAvailable"
				@click="updateApp(); drawer = false;"
			>
				<v-list-item-icon>
					<v-icon color="green darken-2">mdi-arrow-expand-up</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title class="green--text text--darken-1">Update Application</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<template v-for="(item, i) in items">

				<template v-if="item.group">
					<div :key="i">
						<v-divider></v-divider>
						<v-subheader>{{item.header}}</v-subheader>
						<v-list-item link
							v-for="(subitem, j) in item.items"
							:key="`${i}${j}-${subitem.title}`"
							:to="subitem.link"
						>
							<v-list-item-icon>
								<v-icon>{{ subitem.icon }}</v-icon>
							</v-list-item-icon>

							<v-list-item-content>
								<v-list-item-title>{{ subitem.title }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider></v-divider>
					</div>
				</template>

				<v-list-item v-else link
					:key="`${i}-${item.title}`"
					:to="item.link"
				>
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>

			<v-list-item v-if="!!user.profile"
				class="logout"
				@click="logout(); drawer = false;"
			>
				<v-list-item-icon>
					<v-icon>mdi-logout</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>Logout</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

		</v-list>

	</v-navigation-drawer>
</template>

<script>
import { computed } from "@vue/composition-api";
import { useModelWrapper } from "@/logic/model-wrapper";
import useUser from "@/composables/useUser";
import useAppUpdate from "@/composables/useAppUpdate";

export default {
	name: 'AppSidenav',
	props: { open:Boolean, items:Array },
	emits: ["update:open"],

	setup(props, {emit}) {
		const { user, name, logout } = useUser();
		const { available:appUpdateAvailable, triggerUpdate:updateApp } = useAppUpdate();
		const siteName = computed(() => user.value?.profile?.site_name || "");

		return {
			drawer: useModelWrapper(props, emit, "open"),
			user,
			name,
			siteName,
			logout,
			appUpdateAvailable,
			updateApp,
		};
	},
};
</script>

<style lang="scss" scoped>
	.v-list {
		//height: calc(100% - 67px);

		.v-list-item {
			flex: 0;
		}
	}
</style>
