import { reactive, toRefs } from "@vue/composition-api";
import flow from "lodash/fp/flow";

const state = reactive({
	attrs: {
		show: false,
		color: "primary",
	},
	icon: "mdi-plus",
	on: {}, // Dynamic event handler. See https://vuejs.org/v2/api/#v-on
});

export default function() {
	const show = (delay=250) => {
		if(delay > 0) return setTimeout(show.bind(null, 0), delay);
		state.attrs.show = true;
	}
	const hide = () => state.attrs.show = false;

	const setListener = (listener={}) => state.on = listener;
	const setIcon = (icon) => state.icon = icon;
	const setAttrs = (attrs) => {
		state.attrs = {
			...state.attrs,
			...attrs,
		};
	};

	const animate = (fn) => {
		hide();

		const invokeThenShow = flow([fn, show]);
		setTimeout(invokeThenShow, 250);
	};

	return {
		...toRefs(state),
		show, hide,
		animate,

		setListener,
		setIcon,
		setAttrs,
	};
}
