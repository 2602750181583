function Subject() {
	this.observers = [];
}

Subject.prototype.subscribe = function(ob) {
	this.observers.push(ob);
	return this.unsubscribe.bind(this, ob);
};

Subject.prototype.unsubscribe = function(ob) {
	this.observers = this.observers.filter(_ob => _ob !== ob);
};

Subject.prototype.fire = function(...args) {
	this.observers.forEach( ob => ob.apply(null, args) );
}
Subject.prototype.next = Subject.prototype.fire;

Subject.prototype.asObservable = function() {
	return { subscribe: this.subscribe.bind(this) };
};

export default Subject;
