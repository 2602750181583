export default {
	bind: function(el, binding, vNode) {
		if(typeof binding.value !== "function") {
			const compName = vNode.context.name;
			let warning = `[page-bottom]: provided expression '${binding.expression}' is not a function!`;
			if(compName) warning += `Found in component '${compName}'`;
			console.warn(warning);
			return;
		}

		el.scrollListener = checkAtPageBottom(el, binding.value);
		window.addEventListener("scroll", el.scrollListener);
	},

	unbind: function(el) {
		window.removeEventListener("scroll", el.scrollListener);
		el.scrollListener = undefined;
	},
};

const checkAtPageBottom = (element, callback) => {
	return () => {
		const bottomOfWindow = Math.floor(element.getBoundingClientRect().bottom *.9) <= window.innerHeight;
		if(bottomOfWindow) callback();
	};
}
