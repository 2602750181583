import { reactive, watch } from "@vue/composition-api";

const state = reactive({
	show: false,
	timeout: 5000,
	message: "",

	action: {
		enabled: true,
		color: "primary",
		icon: "mdi-close",
		text: null,
		on: {},
	},
});

const enableAction = (enabled) => state.action.enabled = enabled;
const show = (msg, options = {action:{}, timeout:5000}) => {
	const { action, timeout } = options;

	const hasAction = action && action.onClick && (action.icon || action.text);
	if(hasAction) enableAction(true);

	if(action && "icon" in action) setActionIcon(action.icon);
	else if(action && "text" in action) setActionText(action.text);

	if(action && "onClick" in action) setListener({click:action.onClick});
	else setListener({});

	state.message = msg;
	state.timeout = timeout || 5000;
	state.show = true;
};

let handle = null;
const showWithoutAction = (msg, timeout=5000) => {
	if(handle) handle();
	handle = watch(() => state.show, (visible) => {
		if(visible) return;
		enableAction(true);
		handle();
		handle = null;
	});

	enableAction(false);
	show(msg, {timeout});
};
const hide = () => state.show = false;

const setListener = (listener={}) => state.action.on = listener;
const setActionIcon = (icon) => {
	state.action.icon = icon;
	state.action.text = null;
};
const setActionText = (text) => {
	state.action.text = text;
	state.action.icon = null;
};

export default function() {
	return {
		state,
		show, hide,
		showWithoutAction,

		setListener,
		setActionIcon,
		setActionText,
	};
}

export function TextAction(text, onClick) {
	return { text, onClick };
}

export function IconAction(icon, onClick) {
	return { icon, onClick };
}

export const CLOSE_ACTION = Object.freeze(IconAction("mdi-close", hide));
