<template>
	<v-snackbar tile center bottom
		width="100%"
		max-width="600px"
		:value="showNotification && notAtLogin"
		:timeout="-1"
	>
		{{ updating ? "Updating..." : "Update available!" }}
		<template #action="{ attrs }">
			<v-btn text
				:loading="updating"
				color="primary"
				v-bind="attrs"
				@click="triggerUpdate"
			>
				Update
			</v-btn>
			<v-btn icon @click="hideNotification">
				<v-icon color="grey lighten-1">mdi-close</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
import { reactive, toRefs, computed } from "@vue/composition-api"
import useAppUpdate from "@/composables/useAppUpdate";

export default {
	name: "AppUpdateNotification",
	props: {},
	components: {},

	setup(props, {root}) {
		const appUpdate = useAppUpdate();

		const state = reactive({
			notAtLogin: computed(() => root.$route.name !== "Login"),
		});
		return { ...toRefs(state), ...appUpdate };
	},
};
</script>

<style lang="scss" scoped>
	.v-snack {
		z-index: 999;
		transition: padding-bottom 0.25s ease;
	}
</style>
