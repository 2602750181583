import { ref, computed } from "@vue/composition-api";

const state = ref("");

export default function() {
	const title = computed(() => state.value);
	const setTitle = (title) => state.value = title;

	return { title, setTitle };
}
