import cloneDeep from "lodash/fp/cloneDeep";
import Day from "./day";

const DEFAULT_OUTPUT_VALUE = 72;

export default class Schedule {

	constructor(id, data) {
		this.data = data;
		this.id = id;
		this._name = "";
		this._area = "";
		this._doc = null;
	}

	get name() { return this._name; }
	set name(val) { this._name = val; }

	get area() { return this._area; }
	set area(val) { this._area = val; }

	get doc() { return this._doc; }
	set doc(val) { this._doc = val; }

	getDay(day) { return this.data[day] || null; }
	getDays() { return extractWeekdays(this.data); }

	get defaultOutput() { return this.data.default_output; }
	set defaultOutput(val) { this.data.default_output = val; }

	get isEmpty() {
		return Object.values(this.getDays())
			.every(day => day.events.length === 0);
	}

	// Use this in favor of cloning a Schedule instance. Using cloneDeep causes issues when we enable persistance
	// for the firestore database because the of the .doc property (Firebstore DocumentReference) being cloned.
	clone() {
		const days = this.getDays();
		Object.keys(days).forEach(prop => {
			days[prop] = days[prop].events;
		});
		const data = cloneDeep(days);
		const clone = Schedule.make(this.id, data);
		clone.id = this.id;
		clone.doc = this.doc;
		clone.name = this.name;
		clone.area = this.area;
		return clone;
	}

	static make(id, data) {
		const sunday =    new Day("Sunday",    data.sunday);
		const monday =    new Day("Monday",    data.monday);
		const tuesday =   new Day("Tuesday",   data.tuesday);
		const wednesday = new Day("Wednesday", data.wednesday);
		const thursday =  new Day("Thursday",  data.thursday);
		const friday =    new Day("Friday",    data.friday);
		const saturday =  new Day("Saturday",  data.saturday);

		Day.relateDays(sunday,    saturday,  monday);
		Day.relateDays(monday,    sunday,    tuesday);
		Day.relateDays(tuesday,   monday,    wednesday);
		Day.relateDays(wednesday, tuesday,   thursday);
		Day.relateDays(thursday,  wednesday, friday);
		Day.relateDays(friday,    thursday,  saturday);
		Day.relateDays(saturday,  friday,    sunday);

		const schedule = { sunday, monday, tuesday, wednesday, thursday, friday, saturday };

		return new Schedule(id, schedule);
	}

}

const extractWeekdays = ({ sunday, monday, tuesday, wednesday, thursday, friday, saturday }) =>
	({ sunday, monday, tuesday, wednesday, thursday, friday, saturday });

export const scheduleConverter = {
	fromFirestore(snapshot, options) {
		const data = snapshot.data(options);
		const weekdays = extractWeekdays(data);
		const schedule =  Schedule.make(snapshot.id, weekdays);
		schedule.name = data.name;
		schedule.doc = snapshot.ref;
		schedule.defaultOutput = data.default_output || DEFAULT_OUTPUT_VALUE;
		return schedule;
	},

	toFirestore(schedule) {
		let days = schedule.getDays();
		Object.keys(days).forEach(prop => {
			days[prop] = days[prop].events;
		});
		const defaultOutput = schedule.defaultOutput || DEFAULT_OUTPUT_VALUE;
		return { ...days, default_output:defaultOutput };
	}
};
