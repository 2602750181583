import { reactive, computed } from "@vue/composition-api";

const state = reactive({
	available: false,
	updating: false,
	showNotification: false,
});

let registration;
document.addEventListener("swUpdateAvailable", (e) => {
	state.showNotification = state.available = true;
	registration = e.detail.registration;
}, { once:true });

let refreshing = false;
navigator.serviceWorker.addEventListener("controllerchange", () => {
	if(refreshing) return;
	refreshing = true;
	window.location.reload();
});

export default function() {
	const triggerUpdate = () => {
		state.updating = true;
		if(registration && registration.waiting) {
			registration.waiting.postMessage({type:"SKIP_WAITING"});
		}
	};

	const hideNotification = () => state.showNotification = false;

	return {
		available: computed(() => state.available),
		updating: computed(() => state.updating),
		showNotification: computed(() => state.showNotification),

		hideNotification,
		triggerUpdate
	};
}
